import { sendToZendesk, generateTemplate } from "./zendeskService"
import { handleError } from "./handleError"

const generateTags = (flow, details) => {
  const templateTags = ["Website", "Viatris", "Order"]
  let subject, userConfirmationSubject
  if (flow.includes("epharmacy")) {
    templateTags.push("ePharmacy")
    subject = "[Viatris ePharmacy] Order from"
    userConfirmationSubject = "Your Viatris Order"
    if (details?.hasPartnerRequest) templateTags.push("partner_request")
  }
  if (process.env.GATSBY_ENV !== "production") templateTags.push("test")
  return { tags: templateTags, subject, userConfirmationSubject }
}

export const sendRequest = async (
  flow, // process ? flexmed or epharmacy
  details, // state
  fileArray, // list of files
  templateConfirmation, // email template
  templateZendesk, // zendesk template
  callback, // success func
  errorCallback // error func
) => {
  let templateObjects = { ...details[flow] }
  const { emailAddress, coupon } = templateObjects
  const { tags, subject, userConfirmationSubject } = generateTags(
    flow,
    details[flow]
  )
  templateObjects = { ...templateObjects, files: fileArray }
  const requestTemplate = generateTemplate(
    subject,
    emailAddress,
    templateZendesk,
    templateObjects,
    tags
  )
  try {
    const response = await sendToZendesk(requestTemplate, fileArray)
    // await sendEmail(
    //   emailAddress,
    //   process.env.GATSBY_AWS_SES_EMAIL_TO,
    //   process.env.GATSBY_AWS_SES_EMAIL_FROM,
    //   `${userConfirmationSubject}`,
    //   templateConfirmation(
    //     templateObjects,
    //     fileArray,
    //     response.data.request.id
    //   ),
    //   async () => {
    //     if (coupon?.code) {
    //       const condition =
    //         coupon.totalUseLimit !== null && coupon.totalUseLimit > 0
    //       const totalUseLimit = condition ? coupon.totalUseLimit - 1 : null
    //       try {
    //         await api.patch(`/coupons/${coupon.code}/`, { totalUseLimit })
    //       } catch (error) {}
    //     }
    //     callback(emailAddress)
    //   }
    // fileArray.map(item => ({ flow: item.flow, filename: item.fileName }))
    // )
    callback(emailAddress)
  } catch (error) {
    console.log(error)
    // sendEmail(
    //   `sitelcare@medgrocer.com`,
    //   SENDER,
    //   `${
    //     process.env.NODE_ENV === "production" ? "" : "[TEST]"
    //   } ${userConfirmationSubject}`,
    //   templateConfirmation(healthSurvey, vitalSigns, medicineRequest),
    //   () => callback(),
    //   fileArray.map(item => ({ flow: item.flow, filename: item.fileName }))
    // );
    handleError({ error, errorCallback, message: null })
  }
}
