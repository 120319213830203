export const errorZendeskTemplate = ({ subdomain, error, path, env }) => {
  return `
        subdomain: ${subdomain}
        error code: ${error?.code || "N/A"}
        error message: ${error?.message || "N/A"}
        error: ${JSON.stringify(error)} 
        path: ${path}
        env: ${env}
      `
}
