import { generatePrice } from "../../services/computations"

export function orderTicketBody(request) {
  let {
    addressType,
    firstName,
    lastName,
    coupon,
    beneficiaryFirstName,
    beneficiaryLastName,
    beneficiaryType,
    beneficiaryOthers,
    emailAddress,
    mobileNumber,
    medicines,
    deliveryAddress,
    recipientMobileNumber,
    orderDetails,
    birthday,
    employeeIDNumber,
    orderNotes,
    personalEmail,
    designation,
  } = request

  let medicinesArray = ""

  // medicines.map((medicine) => {
  //   medicinesArray += `${medicine.productID}, ${medicine.productTitle}, Qty: ${
  //     medicine.qty
  //   }, ₱${generatePrice(medicine)}, Intake per day: ${medicine.dosage}, Treatment Duration: ${medicine.duration} days\n`
  // })

  medicines.map((medicine) => {
    medicinesArray += `Product ID: ${medicine.productID} \nMedicine: ${
      medicine.productTitle
    } \nQty: ${medicine.qty}\nLine Total: ₱${generatePrice(
      medicine
    )} \nIntake per day: ${medicine.dailyIntake} \nIndication: ${
      medicine.indication
    } \nOthers: ${medicine.others}\n\n`
  })

  let discountApplied = coupon.code.includes("PRIMARY") ? "100% off" : "50% off"

  return `Customer First Name: ${firstName}
  Customer Last Name: ${lastName}
  Beneficiary First Name: ${beneficiaryFirstName}
  Beneficiary Last Name: ${beneficiaryLastName}
  Beneficiary Type: ${beneficiaryType.type}
  Beneficiary Relationship: ${
    beneficiaryOthers ? beneficiaryOthers : beneficiaryType.label
  }
  Customer Email: ${emailAddress}
  Personal Email: ${personalEmail}
  Designation: ${designation}
  Contact Number: ${mobileNumber}
  Recipient's Mobile Number: ${recipientMobileNumber}
  Address Type: ${addressType} 
  Street Address: ${deliveryAddress.streetAddress} 
  Barangay: ${
    deliveryAddress.barangay.value ? deliveryAddress.barangay.value : "NA"
  }
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Birthday: ${birthday.month.value} ${birthday.date.value}, ${birthday.year}
  Employee ID: ${employeeIDNumber}
  
  Receipt Items: 
  ${medicinesArray}

  Medicines Total: ₱${parseFloat(orderDetails.total).toFixed(2)}
  Delivery Fee: ₱${orderDetails.deliveryFee.noDiscount}
  Subtotal: ₱${(
    parseFloat(orderDetails.total) +
    parseFloat(orderDetails.deliveryFee.noDiscount)
  ).toFixed(2)}
  Discount: ${discountApplied}
  Total: ₱${(
    parseFloat(orderDetails.subTotal) +
    parseFloat(orderDetails.deliveryFee.withDiscount)
  ).toFixed(2)}
  Order notes: ${orderNotes ? orderNotes : "NA"}
  Delivery notes: ${deliveryAddress?.notes ? deliveryAddress.notes : "NA"}
  `
}
