import {
  accumulatePrice,
  generateDeliveryFee,
  accumulateVat,
} from "../../services/computations"
import { getCouponDiscountAmount } from "../../services/coupon"
import specialCoupons from "../specialCoupons.json"

export function orderConfirmation(request, files, zendeskID) {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    medicines,
    addressType,
    deliveryAddress,
    birthday,
    employeeIDNumber,
    orderDetails
  } = request


  const handleViewMedicines = () => {
    let medicineString = ""
    medicines.map((medicine) => {
      medicineString += `<tr>
                <td>${medicine.productTitle}</td>
                <td style="text-align: right;">${medicine.qty}</td>
                <td style="text-align: right;"> Php ${(
                  medicine.qty *
                  (
                    medicine.price_list.vatex_unit_price +
                    medicine.price_list.vat
                  ).toFixed(2)
                ).toFixed(2)}</td>
            </tr> `
      return null
    })
    return medicineString
  }

  const handleViewFiles = () => {
    let fileString = ""
    files.map((file) => {
      fileString += `- ${file.filename} <br>`
      return null
    })
    return fileString
  }

  // const computeOrderTotal = () => {
  //   // if (medicines.length === 1)
  //   //   return parseFloat(
  //   //     medicines[0].qty *
  //   //       (
  //   //         medicines[0].price_list.vatex_unit_price +
  //   //         medicines[0].price_list.vat
  //   //       ).toFixed(3)
  //   //   ).toFixed(2);
  //   // const subtotal = medicines.reduce((accumulator, currentValue) => {
  //   //   let currentAccumulator = accumulator;
  //   //   if (typeof accumulator === "object")
  //   //     currentAccumulator = parseFloat(
  //   //       accumulator.qty *
  //   //         (
  //   //           accumulator.price_list.vatex_unit_price +
  //   //           accumulator.price_list.vat
  //   //         ).toFixed(2)
  //   //     );

  //   //   return (
  //   //     currentAccumulator +
  //   //     parseFloat(
  //   //       currentValue.qty *
  //   //         (
  //   //           currentValue.price_list.vatex_unit_price +
  //   //           currentValue.price_list.vat
  //   //         ).toFixed(2)
  //   //     )
  //   //   );
  //   // });
  //   //
  // //   let subtotal =
  // //     parseFloat(accumulatePrice(medicines).toFixed(2)) +
  // //     generateDeliveryFee(deliveryAddress.province.value) -
  // //     parseFloat(
  // //       getCouponDiscountAmount(
  // //         coupon,
  // //         medicines,
  // //         generateDeliveryFee(deliveryAddress.province.value)
  // //       )
  // //     ) -
  // //     (specialCoupons.includes(coupon?.code)
  // //       ? parseFloat(accumulateVat(medicines))
  // //       : 0)
  // //   return `<b>${subtotal.toFixed(2)}</b>`
  // }

  const zendeskIDTemplate = zendeskID
    ? `<h3 style="text-align: center;">Viatris Order Reference Number</h3><h3 style="text-align: center; background: #fadea4; padding: 1em; margin: auto; width: 20em;">${zendeskID}</h3>`
    : ""


  return /*html*/ `
    <!DOCTYPE html
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html lang="en">

<head>
    <style>
        body {
            font-family: 'Lato', sans-serif;
        }

        .container {
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          flex-direction: row;
          padding: 25px;
          width: 100%;
          justify-content: center;
        }
      
        @media only screen and (min-width: 770px) {
          .content {
            width: 60%;
          }
        }

        table {
            border-collapse: collapse;
            width: 100%;
        }

        table,
        th,
        td {
            border: 1px solid #ccc;
        }

        td {
            width: 30%;
            padding: 10px;
        }

        th {
            text-align: left;
            padding: 10px;
        }

        .is-bordered {
            border: 1px solid #ccc;
        }

        h3 {
          margin-top: .75rem;
        }

        .terms {
          padding: 10px;
          background: #EEEEEE;
          font-size: 11px;
          color: #606060
        }

        .has-text-right {
          text-align: right
        }

        .terms th, .terms td {
          padding: 5px;
      }
    </style>
</head>

<body>
<div class="container">
  <div class="content">
    <p>Hi ${firstName} ${lastName},</p>
    <p>Thank you for ordering from MedGrocer.</p>
    
    ${zendeskIDTemplate}
    <br />
    <p>Our pharmacist will contact you during business hours (Mon - Fri, 9AM-5PM) to verify your order details. Please reply within 12 hours so that your order will not be cancelled.</p>
    
    <p>Your order will be delivered after 2-5 business days after you confirm your order. We will text you on the day of delivery for the estimated time of arrival.</p>
    
    <p>Please note that the total amount below is not final until verified by our pharmacist.</p>

    <p>
        <h2>Review Your Responses</h2>
        Please review your responses to make sure all details are correct.
    </p>
    <h3>Personal Information</h3>
    <table class='is-bordered'>
        <tbody>
            <tr>
                <th>Name</th>
                <td>${firstName} ${lastName}</td>
            </tr>
            <tr>
                <th>Mobile Number</th>
                <td>${mobileNumber}</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>${emailAddress}</td>
            </tr>
            <tr>
                <th>Birthday</th>
                <td>${birthday.month.value} ${birthday.date.value}, ${
    birthday.year
  }</td>
            </tr>
            <tr>
                <th>Delivery Address (Work)</th>
                <td>${deliveryAddress.streetAddress} ${deliveryAddress.city}, ${deliveryAddress.province.value} 
  ${
    deliveryAddress?.notes ? `, (${deliveryAddress?.notes})` : ""
  }</td>
            </tr>
            ${
              employeeIDNumber
                ? `<tr>
                <th>Employee ID Number</th>
                <td>${employeeIDNumber}</td>
            </tr>`
                : ""
            }
        </tbody>
    </table>

    <h3>Medicines Ordered</h3>
    <table class='is-bordered'>
        <tbody>
            <tr>
                <th>SKU</th>
                <th>Quantity</th>
                <th>Total</th>
            </tr>

            ${handleViewMedicines()}

            <tr>
              <td colspan="2" class="has-text-right"><b>Total Payment Due (For Salary Deduction):</b></td>
              <td class="has-text-right"><b>Php ${orderDetails.subTotal}</b></td>
            </tr>
            
        </tbody>
    </table>

    <br>
    <br>
        ${
          files.length > 0
            ? `
          <h3>Files Uploaded (${files.length})</h3>
              ${handleViewFiles()}
          </table>
        `
            : ""
        }
    
        
<br />
    <p>This is an automated email. </p>

    <p>Thank you. We hope to serve you again in the future.</p>

    <p>Regards, <br /> The MedGrocer Team</p>
    
    <b>Data Privacy</b>
      <ul>
        <li>The above information is correct and I give MedGrocer and its partners the authority to collect, store, and use my information for Medical Allowance Program and related administrative activities. Subject to certain exceptions allowed by the law, I may revise my information and inform MedGrocer and its partners to update and store the same. MedGrocer and its partners may store my information for a period of six (6) years from the end of the program without any further consent needed from my end.</li>
        <li>I will indemnify and hold MedGrocer and their partners free from any liabilities that may arise as a consequence of my decision to join the Medical Allowance Program. I also acknowledge that MedGrocer shall not be responsible nor liable for any loss or damage incurred or suffered as a consequence of: A) any delay or inability of MedGrocer to perform any of its obligations due to any mechanical, data processing, telecommunication failure, act of God, civil disturbance, any event outside MedGrocer’s control, or as a consequence of any fraud or forgery; and B) any damage to, loss of, or inability to retrieve any data that may be stored in the MedGrocer database.</li>
      </ul>
    </div>
  </div>
</div>
</body>

</html>
  `
}
