import { errorZendeskTemplate } from "../templates/errorZendeskTemplate"
import { sendToZendesk } from "./zendeskService"

const sendErrorTicket = async (error) => {
  const IS_TEST = process.env.GATSBY_ENV !== "production"
  let tags = ["website_error"]

  let subject = `${IS_TEST ? "[TEST] " : ""}Website error from ${
    process.env.GATSBY_AWS_S3_BUCKET_NAME
  }`

  let errorPayload = {
    subdomain: process.env.GATSBY_AWS_S3_BUCKET_NAME,
    error,
    path: window.location.pathname,
    env: process.env.GATSBY_ENV,
  }

  let requestBody = {
    type: "incident",
    tags,
    subject,
    requester: {
      name: `MedGrocer IT`,
      email: "it@medgrocer.com",
    },
    comment: {
      body: errorZendeskTemplate(errorPayload),
    },
  }

  await sendToZendesk(requestBody, [])
}

export const handleError = async ({ error, errorCallback, errorMessage }) => {
  try {
    await sendErrorTicket(error)
  } finally {
    const NETWORK_ERROR_MESSAGE = "Network Error"

    if (error.message === NETWORK_ERROR_MESSAGE)
      errorMessage =
        "Submission Error: Please check your internet connection and try again."

    errorCallback({
      message: errorMessage,
    })
  }
}
