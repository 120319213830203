import React, { useContext, useState, Fragment, useEffect } from "react"
import { navigate } from "gatsby"

import ActionButtons from "../Elements/ActionButtons"
import Section from "../Elements/Section"
import EditDetailsButton from "../Summary/EditDetailsButton"
import {
  generateDeliveryFee
} from "../Epharmacy/services/computations"

import { AppContext } from "../../context/AppContext"
import PersonalDetailsSummary from "../Summary/PersonalDetailsSummary"
import MedsOrderedSummary from "../Summary/MedsOrderedSummary"
import UploadDocumentsSummary from "../Summary/UploadedDocumentsSummary"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"
import OrderSubtotalSummary from "../Summary/OrderSubtotalSummary"

import { sendRequest } from "../../services/order"
import { shouldBeDisabled } from "./services/summary"
import { orderTicketBody } from "./utils/templates/epharmacyOrderZendeskTemplate"
import { orderConfirmation } from "./utils/templates/epharmacyOrderEmailTemplate"

const Summary = ({ backRoute }) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy, documents, employeeId } = state
  const {
    addressType,
    medicines,
    orderDetails,
    deliveryAddress,
    beneficiaryType
  } = epharmacy
  // const deliveryAddress = addressType==="Home" ? epharmacy.deliveryAddress:epharmacy.workAddress
  let uploadedDocuments = [...documents]
  if (employeeId.front.path) uploadedDocuments.push(employeeId.front)
  if (employeeId.front.path) uploadedDocuments.push(employeeId.back)

  const ORDER_NOTES_HEADER = epharmacy?.hasPartnerRequest
    ? "Partner Request"
    : "Order Notes"

  const deliveryFee = {
    noDiscount:generateDeliveryFee(addressType,deliveryAddress.province.value),
    withDiscount: generateDeliveryFee(addressType,deliveryAddress.province.value,beneficiaryType.type)
  }

  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest(
      "epharmacy",
      state,
      uploadedDocuments,
      orderConfirmation,
      orderTicketBody,
      async () => {
        await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
        await dispatch({ type: "RESET_DETAILS" })
        navigate("/epharmacy/completed")
      },
      () => {
        dispatch({
          type: "SHOW_TOAST",
          payload: {
            message:
              "There was a problem in sending your order. Please try again.",
            color: "danger",
          },
        })
        setLoading(false)
      }
    )
  }

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    dispatch({
      type: "SAVE_DELIVERY_FEE",
      payload: deliveryFee
    })
  }, [])

  return (
    <Fragment>
      <Section
        title="Personal Details"
        addOns={{
          right: <EditDetailsButton route="/epharmacy/checkout" />,
        }}
      >
        <PersonalDetailsSummary
          data={epharmacy}
          deliveryAddress={deliveryAddress}
          flow="epharmacy"
        />
      </Section>
      <UploadDocumentsSummary
        files={uploadedDocuments}
        route="/epharmacy/upload"
      />
      <MedsOrderedSummary medicines={medicines} route="/epharmacy/order" />

      <OrderSubtotalSummary
        medicinesSubtotal = {orderDetails.subTotal}
        medicinesTotal = {orderDetails.total}
        deliveryFee = {deliveryFee}
      />
      {epharmacy?.orderNotes && (
        <Section
          title={ORDER_NOTES_HEADER}
          addOns={{
            right: <EditDetailsButton route="/epharmacy/checkout" />,
          }}
        >
          {epharmacy?.orderNotes?.split("\n").map((orderNote) => (
            <p className="px-1 is-size-6">{orderNote}</p>
          ))}
        </Section>
      )}
      <ConsentAndAuthorization handleOnSubmit={handleOnSubmit} flow="epharmacy">
        <div className="notification is-warning has-text-centered is-size-6 mt-3">
          Order is not final until verified by our pharmacist via email.
        </div> 
        <ActionButtons
          submit={{
            label: "Submit",
            loading: loading,
            disabled: shouldBeDisabled(epharmacy, medicines, documents),
          }}
          back={{ label: "Back", link: backRoute }}
        />
      </ConsentAndAuthorization>
    </Fragment>
  )
}

export default Summary
